import BroadcastChannel from 'broadcast-channel';
import { authAPI, toastUtil } from 'msdf-core-ui';
import history from 'utils/history';
import * as routes from 'constants/routes';

export const logoutChannel = new BroadcastChannel();

export const logoutAllTabsChannelEventListener = () => {
  logoutChannel.onmessage = event => {
    authAPI.logout();
    toastUtil.info({
      message: 'You have been logged out',
      title: 'Info'
    });

    history.push(routes.LOGIN);
    logoutChannel.close();
  };
};
