import { initialize, roles } from 'msdf-core-ui';

import config from 'ccmrConfig';

/**
 * Initializes stuff needed for the application.
 */
export default function init() {
  initialize({
    baseUri: config.baseURI,
    domainName: config.domainName,
    env: config.env,
    allowedRoles: [
      roles.LEA_ADMINISTRATOR,
      roles.SCHOOL_ADMINISTRATOR,
      roles.PRINCIPAL,
      roles.COUNSELOR,
      roles.LEA_SPECIALIST
    ]
  });
}
