import React, { Component } from 'react';

import Router from 'components/Router';

import { logoutAllTabsChannelEventListener } from 'utils/logoutBroadcast';

class App extends Component {
  componentDidMount() {
    logoutAllTabsChannelEventListener();
  }
  render() {
    return <Router />;
  }
}

export default App;
