import { generatePath } from 'react-router-dom';

export const HOME = '/';

export const LOGIN = '/login';

export const DETAILS = '/details';
export const PROFILE = '/profile';
export const CCMR_HB3 = '/ccmr-hb3';
export const SCHOOL = '/:year';
export const SCHOOL_HOME = SCHOOL;
export const SCHOOL_CCMR_HB3 = SCHOOL + CCMR_HB3;
export const SCHOOL_DETAILS = SCHOOL + DETAILS;
export const PROFILE_DETAILS = SCHOOL + PROFILE;

export const ABOUT_US = '/about-us';
export const TERMS_AND_CONDITION = '/terms-of-use';
export const PRIVACY_POLICY = '/privacy-policy';
export const NOT_FOUND = '/not-found';

/**
 * A utility to return the base url for detail table
 * to be used to add additional query parameters
 *
 * Eg: returns /:year/details
 * @param {Object} match
 * @returns {string} base url for details table based on current route and user role
 */
export function getDetailsPath(match) {
  const { path } = match;

  return getPath(path + DETAILS);
}

/**
 *  generate the path for different views eg. profile
 * @param {String}   path  path defined value
 * @returns {string} base url for different views based on current route & user role
 */

export function getPath(path) {
  let finalPath;
  const year = window.location.pathname.split('/')[1];

  finalPath = generatePath(path, {
    year: year
  });

  return finalPath;
}

export function getStudentProfilePath() {
  return getPath(PROFILE_DETAILS);
}
