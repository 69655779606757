import React, { lazy, Suspense } from 'react';
import { Router as BrowserRouter, Switch, Route } from 'react-router-dom';

import * as routes from 'constants/routes';

import withScrollToTop from 'components/hoc/withScrollToTop';

import history from 'utils/history';
import Spinner from 'components/common/spinner';

const Login = lazy(() => import('components/login'));
const Home = lazy(() => import('components/dashboard/Home'));
const School = lazy(() => import('components/dashboard/School'));
const About = lazy(() => import('components/common/footer/About'));
const NotFound = lazy(() => import('components/common/not-found'));
const PrivateRoute = lazy(() => import('components/common/PrivateRoute'));
const TermsOfUse = lazy(() => import('components/common/footer/TermsOfUse'));
const PrivacyPolicy = lazy(() => import('components/common/footer/PrivacyPolicy'));

const Router = () => (
  <Suspense fallback={<Spinner />}>
    <BrowserRouter history={history}>
      <Switch>
        <Route exact path={routes.LOGIN} component={Login} />
        <Route exact path={routes.ABOUT_US} component={withScrollToTop(About)} />
        <Route exact path={routes.PRIVACY_POLICY} component={withScrollToTop(PrivacyPolicy)} />
        <Route exact path={routes.TERMS_AND_CONDITION} component={withScrollToTop(TermsOfUse)} />
        <PrivateRoute path={routes.SCHOOL} component={School} />
        <PrivateRoute path={routes.HOME} component={Home} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  </Suspense>
);

export default Router;
